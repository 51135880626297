<!-- 统计面板 -->
<template>
  <div class="stats-panel">
    <div class="stats-panel__hd">
      <slot name="header">{{ title }}</slot>
    </div>
    <div class="stats-panel__bd">
      <vh-row type="flex" class="stats-panel__row">
        <slot></slot>
      </vh-row>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'StatsPanel',
    props: {
      title: String,
      // 可选：start/end/center/space-around/space-between
      justify: {
        type: String,
        default: 'space-around'
      }
    }
  };
</script>
<style lang="less" scoped>
  .stats-panel {
    display: flex;
    flex-direction: column;

    &__hd {
      text-align: left;
      padding: 12px 0 18px 12px;
    }
    &__bd {
      flex: 1;
    }
    &__row {
      flex-wrap: wrap;
      margin: 12px 28px 28px 28px;
    }
  }
</style>
