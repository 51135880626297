<template>
  <div class="product-order-wrap">
    <div class="head-operate" v-show="isSearch || total != 0">
      <div>
        <vh-button
          v-if="isInWebinar"
          type="primary"
          size="medium"
          round
          @click="choseCoupon"
          v-preventReClick
        >
          资料库
        </vh-button>
        <vh-button v-else type="primary" size="medium" round @click="addCoupon" v-preventReClick>
          创建优惠券
        </vh-button>

        <vh-button size="medium" round plain @click="allDelete" :disabled="!couponIds.length">
          批量删除
        </vh-button>
      </div>
      <div class="rightTool">
        <vh-select
          round
          size="medium"
          filterable
          v-model="searchParams.coupon_type"
          style="width: 100px"
          @change="getList('clearPage')"
          placeholder="全部类型"
          class="mr10 mb15"
        >
          <vh-option label="全部类型" value="" />
          <vh-option label="满减券" :value="0" />
          <vh-option label="无门槛" :value="1" />
        </vh-select>
        <vh-input
          size="medium"
          round
          :maxlength="50"
          placeholder="请输入优惠券"
          v-model="searchParams.keyword"
          class="input-with-select mr10 mb15"
          @keyup.enter.native="getList('clearPage')"
          clearable
          @clear="getList('clearPage')"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="getList('clearPage')"></i>
        </vh-input>
      </div>
    </div>
    <div v-if="!total && !isSearch" class="oh">
      <null-page
        v-if="isInWebinar"
        :nullType="'nullData'"
        :text="`您还没有优惠券，请先选择优惠券~<p>优惠券仅适用于「平台购买」方式的商品</p>`"
      >
        <vh-button type="primary" round @click="choseCoupon" v-preventReClick>资料库</vh-button>
      </null-page>
      <null-page
        v-else
        :nullType="'nullData'"
        :text="`您还没有优惠券，快来创建吧！<p>优惠券仅适用于「平台购买」方式的商品</p>`"
      >
        <vh-button type="primary" round @click="addCoupon" v-preventReClick>创建优惠券</vh-button>
      </null-page>
    </div>
    <vh-table
      v-if="total || isSearch"
      :data="tableData"
      tooltip-effect="dark"
      @selection-change="handleTableCheckbox"
      style="width: 100%"
      ref="multipleTable"
      :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
    >
      <vh-table-column type="selection" width="55" align="left" />
      <vh-table-column
        label="名称"
        prop="coupon_name"
        width="150"
        show-overflow-tooltip
      ></vh-table-column>
      <vh-table-column label="类型">
        <template slot-scope="scope">
          <span>
            {{ scope.row.coupon_type | typeFilter }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="优惠内容" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_type == 0">
            满{{ scope.row.threshold_amount }}减{{ scope.row.deduction_amount }}
          </span>
          <span v-if="scope.row.coupon_type == 1">无门槛减{{ scope.row.deduction_amount }}</span>
        </template>
      </vh-table-column>
      <vh-table-column label="优惠商品" width="130">
        <template slot-scope="scope">
          <span
            :class="{ highLight: scope.row.applicable_product_type != 0 }"
            @click="openGoodsList(scope.row)"
          >
            {{ scope.row.applicable_product_type | productFilter }}
            {{ scope.row.applicable_product_type != 0 ? scope.row.goods_num : '' }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="使用时间" width="180">
        <template slot-scope="scope">
          <div v-if="scope.row.validity_type == 0">
            <p>起{{ scope.row.validity_start_time }}</p>
            <p>至{{ scope.row.validity_end_time }}</p>
          </div>
          <span v-if="scope.row.validity_type == 1">
            自领取起{{ scope.row.validity_day }}天内有效
          </span>
        </template>
      </vh-table-column>
      <vh-table-column
        v-if="isInWebinar"
        label="已领取"
        prop="received_sum"
        width="100"
      ></vh-table-column>
      <vh-table-column
        v-if="isInWebinar"
        label="已使用"
        prop="used_sum"
        width="100"
      ></vh-table-column>
      <vh-table-column label="最后操作" v-if="!isInWebinar" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          <p class="link-item-operator">
            {{ scope.row.nick_name }}
          </p>
          <p class="link-item-operator">
            {{ scope.row.updated_at }}
          </p>
        </template>
      </vh-table-column>
      <vh-table-column label="操作" :width="isInWebinar ? 120 : 200" fixed="right">
        <template slot-scope="scope">
          <vh-button
            v-preventReClick
            v-if="!isInWebinar"
            class="btns"
            type="text"
            @click="toCopy(scope.row)"
          >
            复制
          </vh-button>
          <vh-button v-preventReClick class="btns" type="text" @click="toData(scope.row)">
            数据
          </vh-button>
          <vh-button
            v-preventReClick
            v-if="!isInWebinar"
            class="btns"
            type="text"
            @click="toEdit(scope.row)"
          >
            编辑
          </vh-button>
          <vh-button v-preventReClick class="btns" type="text" @click="toDel(scope.row)">
            删除
          </vh-button>
        </template>
      </vh-table-column>
      <div slot="empty">
        <null-page
          text="暂未搜索到您想要的内容"
          nullType="search"
          :height="100"
          v-if="!total"
        ></null-page>
      </div>
    </vh-table>
    <SPagination
      :total="total"
      :page-size="searchParams.page_size"
      :currentPage="searchParams.page"
      @current-change="currentChange"
      @size-change="sizeChange"
    ></SPagination>

    <!-- 弹框 -->
    <ChoseCoupon
      ref="choseCoupon"
      :needBind="true"
      @selected="selectedCoupon"
      source="account"
    ></ChoseCoupon>
    <Goods ref="goods" :couponId="curCouponId"></Goods>
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import { isEmbed } from '@/utils/utils';
  import Goods from './goods.vue';
  import ChoseCoupon from './choseCoupon.vue';

  export default {
    name: 'ProductCouponList',
    props: {
      isInWebinar: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        isEmbed: isEmbed(),
        isSearch: false,
        searchParams: {
          page_size: 10,
          page: 1,
          coupon_type: '',
          keyword: ''
        },
        tableData: [], // 列表数据
        total: 0, // 列表总数
        curCouponId: '',
        couponIds: []
      };
    },
    components: {
      NullPage,
      Goods,
      ChoseCoupon
    },
    created() {
      this.getList('clearPage');
    },
    filters: {
      typeFilter(val) {
        const map = {
          0: '满减券',
          1: '无门槛'
        };
        return map[val];
      },
      productFilter(val) {
        const map = {
          0: '全部商品',
          1: '可用商品',
          2: '不可用商品'
        };
        return map[val];
      }
    },
    methods: {
      // 复选框操作
      handleTableCheckbox(val) {
        this.couponIds = val.map(item => item.coupon_id);
      },
      setIsSearch() {
        for (let key in this.searchParams) {
          if (
            Object.prototype.hasOwnProperty.call(this.searchParams, key) &&
            !['page_size', 'page', 'webinar_id'].includes(key)
          ) {
            if (this.searchParams[key]) {
              this.isSearch = true;
              return;
            }
          }
          this.isSearch = false;
        }
      },
      getRequestParams() {
        return {
          ...this.searchParams,
          keyword: this.searchParams.keyword,
          coupon_type: this.searchParams.coupon_type,
          pos: (this.searchParams.page - 1) * this.searchParams.page_size,
          limit: this.searchParams.page_size
        };
      },
      getList(type) {
        if (type == 'clearPage') {
          this.searchParams.page = 1;
        }
        const params = this.getRequestParams();
        this.setIsSearch();

        let requestObj = '';
        if (this.isInWebinar) {
          params.webinar_id = this.$route.params.str;
          requestObj = 'getWebinarListCoupon';
        } else {
          requestObj = 'getListCoupon';
        }

        this.$fetch(requestObj, params, {
          'Content-Type': 'application/json'
        }).then(res => {
          if (res.code == 200 && res.data) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          }
        });
      },
      toCopy(row) {
        this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/copyCoupon/${row.coupon_id}`);
      },
      toData(row) {
        if (this.isInWebinar) {
          this.$router.push(
            `${isEmbed() ? '/embed' : ''}/live/couponData/${this.$route.params.str}?couponId=${
              row.coupon_id
            }`
          );
        } else {
          this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/viewCoupon/${row.coupon_id}`);
        }
      },
      toEdit(row) {
        this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/editCoupon/${row.coupon_id}`);
      },
      toDel(rows) {
        this.deleteConfirm([rows.coupon_id]);
      },
      allDelete() {
        this.deleteConfirm(this.couponIds);
      },
      deleteConfirm(id) {
        this.$vhConfirm(
          '删除后，用户无法领取该优惠券，之前领取优惠券在有效期内不受影响使用，确定删除？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            roundButton: true,
            customClass: 'zdy-message-box',
            lockScroll: false,
            cancelButtonClass: 'zdy-confirm-cancel'
          }
        ).then(() => {
          let params = {
            coupon_ids: id
          };
          let requestObj = '';
          if (this.isInWebinar) {
            params.webinar_id = this.$route.params.str;
            requestObj = 'batchWebinarDelCoupon';
          } else {
            requestObj = 'batchDelCoupon';
          }

          this.$fetch(requestObj, params, {
            'Content-Type': 'application/json'
          })
            .then(res => {
              if (res && res.code === 200) {
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 刷新页面
                this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
                this.getList('clearPage');
                this.couponIds = [];
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || `删除失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        });
      },
      currentChange(val) {
        this.searchParams.page = val;
        this.getList();
      },
      sizeChange(val) {
        this.searchParams.page = 1;
        this.searchParams.page_size = val;
        this.getList();
      },
      addCoupon() {
        this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/addCoupon`);
      },
      openGoodsList(row) {
        console.log(row);
        if (row.applicable_product_type != 0) {
          this.curCouponId = parseInt(row.coupon_id + '');
          this.$nextTick(() => {
            this.$refs.goods.open();
          });
        }
      },
      choseCoupon() {
        if (this.total >= 200) {
          this.$vhMessage.error('最多选择200个优惠券');
          return;
        }
        this.$refs.choseCoupon.open();
      },
      selectedCoupon(res) {
        console.log(res);
        this.getList('clearPage');
      }
    }
  };
</script>

<style lang="less" scoped>
  .product-order-wrap {
    min-height: 572px;
    .mr10 {
      margin-right: 10px;
    }
    .mb15 {
      margin-bottom: 15px;
    }
    .oh {
      overflow: hidden;
    }
    .head-operate {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      .vh-button {
        height: 36px;
      }
      .rightTool {
        .input-with-select {
          width: 220px;
        }
      }
    }
    .highLight {
      color: #0a7ff5;
      cursor: pointer;
    }
    .products-dialog {
      .flexBox {
        display: flex;
        align-items: center;
        .name {
          margin-left: 10px;
        }
        .gift-cover {
          display: inline-block;
          width: 80px;
          min-width: 80px;
          height: 80px;
          // margin: 3px 0;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e6e6e6;
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }
    }
    .link-item-operator {
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }
</style>
