export const orderStatus = [
  {
    label: '全部订单',
    value: false
  },
  {
    label: '支付成功',
    value: 'SUCCESS'
  },
  {
    label: '待支付',
    value: 'WAITING'
  },
  {
    label: '支付失败',
    value: 'FAILED'
  },
  {
    label: '订单已创建',
    value: 'CREATED'
  }
];

export const buyTypeList = [
  {
    label: '全部',
    value: false
  },
  {
    label: '平台购买',
    value: 1
  },
  {
    label: '自定义购买',
    value: 3
  }
];

export const searchOptions = [
  {
    label: '订单编号',
    value: 'order_no',
    placeholder: '请输入订单编号'
  },
  {
    label: '用户信息',
    value: 'address_info',
    placeholder: '请输入用户姓名/手机号/参会ID/用户参会昵称'
  },
  {
    label: '商品名称',
    value: 'goods_name',
    placeholder: '请输入商品名称'
  },
  {
    label: '活动信息',
    value: 'webinar_info',
    placeholder: '请输入活动名称/活动ID'
  }
];
