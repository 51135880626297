<!-- 商品交易统计F -->
<template>
  <div class="trade-stat">
    <!-- 顶部搜索栏 -->
    <div class="trade-stat__hd">
      <div class="second-title">数据总览</div>
      <div class="search-bar">
        <vh-select
          v-model="timeMode"
          round
          placeholder="请选择"
          size="medium"
          @change="handleSearch"
        >
          <vh-option label="今日实时" value="current"></vh-option>
          <vh-option label="自定义" value="custom"></vh-option>
        </vh-select>
        <div class="what-time what-time--current" v-show="timeMode === 'current'">
          <span>{{ dayjs(currentTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
          <vh-button type="text" class="btn-refresh" @click="handleSearch">刷新</vh-button>
        </div>
        <div class="what-time what-time--custom" v-show="timeMode === 'custom'">
          <vh-date-picker
            v-model="customTimeRange"
            type="daterange"
            align="right"
            size="medium"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="handleSearch"
            round
          ></vh-date-picker>
        </div>
      </div>
    </div>

    <!-- 统计面板 -->
    <StatsPanel>
      <StatsCell title="支付金额（元）">
        <template slot="tipContent">
          1.统计时间内，成功付款的订单折后金额之和（不剔除退款订单）
          <br />
          2.时间定义：以付款成功时间进行统计
        </template>
        {{ statsData.paid_amount }}
      </StatsCell>
      <StatsCell title="成交转化率">
        <template slot="tipContent">1.统计时间内，成交转化率=已付款人数/点击商品人数*100%</template>
        {{ conversionRate }}
      </StatsCell>
      <StatsCell title="已付款订单数">
        <template slot="tipContent">
          1.统计时间内，成功付款的订单数，一个订单对应唯一一个订单号（不剔除退款订单）
          <br />
          2.时间定义：以付款成功时间进行统计
        </template>
        {{ statsData.paid_num }}
      </StatsCell>
      <StatsCell title="未付款订单数">
        <template slot="tipContent">
          1.统计时间内，未付款成功的订单数，一个订单对应唯一一个订单号（不剔除退款订单）
          <br />
          2.时间定义：以下单时间进行统计
        </template>
        {{ statsData.unpaid_num }}
      </StatsCell>
      <StatsCell title="付款人数">
        <template slot="tipContent">
          1.统计时间内，成功付款的人数，一个人在统计时间内支付多次记为1人（不剔除退款订单）
          <br />
          2.时间定义：以付款成功时间进行统计
        </template>
        {{ statsData.paid_user_num }}
      </StatsCell>
      <StatsCell title="付款件数">
        <template slot="tipContent">
          1.统计时间内，成功付款订单的商品件数之和（不剔除退款订单）
          <br />
          2.时间定义：以付款成功时间进行统计
        </template>
        {{ statsData.paid_goods_num }}
      </StatsCell>
      <StatsCell
        title="点击商品次数"
        tipContent="统计时间内，点击商品或购买按钮的次数，一个人在统计时间内访问多次记为多次"
      >
        {{ statsData.goods_pv }}
      </StatsCell>
      <StatsCell
        title="点击商品人数"
        tipContent="统计时间内，点击商品或购买按钮的人数，一个人在统计时间内访问多次记为1次"
      >
        {{ statsData.goods_uv }}
      </StatsCell>
    </StatsPanel>
  </div>
</template>
<script>
  import { StatsPanel, StatsCell } from '@/components/StatsPanel/index.js';
  import { sessionOrLocal } from '@/utils/utils';
  import to from 'await-to-js';
  export default {
    name: 'TradeStats',
    components: {
      StatsPanel,
      StatsCell
    },
    data() {
      return {
        webinarId: undefined, //当前活动id
        userInfo: null,

        // 查询条件相关
        timeMode: 'current', // 时间模式，可选：current 今日实时；custom 自定义
        currentTime: Date.now(),
        customTimeRange: '', //自定义时间范围
        pickerOptions: {
          shortcuts: [
            {
              text: '今日',
              onClick: picker => {
                let childrenArray = Array.from(picker.$el.firstChild.firstChild.children);
                childrenArray.forEach(item => {
                  item.style.color = '#666';
                });
                picker.$el.firstChild.firstChild.children[0].style.color = '#FB3A32';
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime());
                start.setTime(start.getTime());
                picker.$emit('pick', [start, end]);
                this.timeType = 0;
              }
            },
            {
              text: '近7日',
              onClick: picker => {
                let childrenArray = Array.from(picker.$el.firstChild.firstChild.children);
                childrenArray.forEach(item => {
                  item.style.color = '#666';
                });
                picker.$el.firstChild.firstChild.children[1].style.color = '#FB3A32';
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime() - 3600 * 1000 * 24);
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
                this.timeType = 1;
              }
            },
            {
              text: '近30日',
              onClick: picker => {
                let childrenArray = Array.from(picker.$el.firstChild.firstChild.children);
                childrenArray.forEach(item => {
                  item.style.color = '#666';
                });
                picker.$el.firstChild.firstChild.children[2].style.color = '#FB3A32';
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime() - 3600 * 1000 * 24);
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
                this.timeType = 2;
              }
            }
          ],
          // disabledDate是一个函数,参数是当前选中的日期值,这个函数需要返回一个Boolean值,
          disabledDate: time => {
            return this.dealDisabledData(time);
          }
        },

        statsData: {
          paid_amount: 0, //支付金额（元）
          paid_num: 0, //已付款订单数
          unpaid_num: 0, //未付款订单数
          paid_user_num: 0, //付款人数
          paid_goods_num: 0, //付款件数
          goods_pv: 0, //点击商品次数
          goods_uv: 0 //点击商品人数
        }
      };
    },
    computed: {
      conversionRate() {
        if (this.statsData.goods_uv > 0) {
          return ((this.statsData.paid_user_num * 100) / this.statsData.goods_uv).toFixed(2) + '%';
        }
        return 0;
      }
    },
    created() {
      this.webinarId = this.$route.params?.str;
      this.userInfo =
        (sessionOrLocal.get('userInfo') && JSON.parse(sessionOrLocal.get('userInfo'))) || {};
      this.handleSearch();
    },
    methods: {
      dealDisabledData(time) {
        return time.getTime() > Date.now(); //设置选择今天以及今天以前的日期
      },
      handleSearch() {
        this.fetchData();
      },
      // 请求数据
      fetchData: async function () {
        // this.loading = true;
        if (!this.webinarId || !this.userInfo?.user_id) {
          this.$message.error('参数错误');
          return;
        }
        let startAt, endAt;
        if (this.timeMode === 'current') {
          this.currentTime = Date.now();
          startAt = dayjs(this.currentTime).format('YYYY-MM-DD 00:00:00');
          endAt = dayjs(this.currentTime).format('YYYY-MM-DD HH:mm:ss');
        } else if (this.timeMode === 'custom') {
          startAt = dayjs(this.customTimeRange[0]).format('YYYY-MM-DD 00:00:00');
          endAt = dayjs(this.customTimeRange[1]).format('YYYY-MM-DD 23:59:59');
        }
        const params = {
          start_at: startAt,
          end_at: endAt,
          webinar_id: this.webinarId,
          business_uid: this.userInfo.user_id
        };
        const [err, res] = await to(
          this.$fetch('goodsStatGet', params, {
            'Content-Type': 'application/json'
          })
        );
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求失败');
          return;
        }
        this.statsData = res.data;
      }
    }
  };
</script>
<style lang="less" scoped>
  .trade-stat {
    &__hd {
      margin-top: 6px;
      margin-left: 28px;

      .second-title {
        font-size: 20px;
        line-height: 24px;
      }
      .search-bar {
        margin-top: 24px;

        .what-time {
          display: inline-block;
          margin-left: 12px;

          .btn-refresh {
            margin-left: 12px;
          }
        }
      }
    }
  }
</style>
