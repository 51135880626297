<template>
  <div class="product-order-wrap">
    <!--   -->
    <div class="head-operate" v-show="isSearch || total != 0">
      <!-- 日期时间选择器 -->
      <vh-date-picker
        v-model="pickDate"
        round
        size="medium"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetimerange"
        prefix-icon="iconfont-v3 saasicon_date"
        @change="changeDate"
        :unlink-panels="true"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        style="width: 360px"
        class="mr10 mb15"
      />
      <!-- 渠道来源 -->
      <vh-select
        round
        size="medium"
        filterable
        v-model="searchParams.channel_source_name"
        style="width: 100px"
        @change="getList('clearPage')"
        placeholder="渠道来源"
        class="mr10 mb15"
      >
        <vh-option
          v-for="opt in channelList"
          :key="opt.label"
          :label="opt.label"
          :value="opt.value"
        />
      </vh-select>
      <!-- 渠道来源 -->
      <vh-select
        round
        size="medium"
        filterable
        v-model="searchParams.buy_type"
        style="width: 112px"
        @change="getList('clearPage')"
        placeholder="购买方式"
        class="mr10 mb15"
      >
        <vh-option
          v-for="opt in buyTypeList"
          :key="opt.label"
          :label="opt.label"
          :value="opt.value"
        />
      </vh-select>
      <!-- 订单状态 -->
      <vh-select
        size="medium"
        round
        filterable
        v-model="searchParams.order_status"
        style="width: 112px"
        @change="getList('clearPage')"
        placeholder="订单状态"
        class="mr10 mb15"
      >
        <vh-option
          v-for="(opt, optIndex) in orderStatus"
          :key="optIndex"
          :label="opt.label"
          :value="opt.value"
        />
      </vh-select>
      <vh-input
        size="medium"
        round
        :maxlength="50"
        :placeholder="customPlaceholder"
        v-model="customSearchOptVal"
        style="width: 460px"
        class="input-with-select mr10 mb15"
        @keyup.enter.native="getList('clearPage')"
        clearable
        @clear="getList('clearPage')"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="getList('clearPage')"></i>
        <vh-select
          style="width: 108px"
          round
          v-model="customSearchOpt"
          slot="prepend"
          placeholder="请选择"
          @change="changeCustomPlaceholder"
        >
          <vh-option
            v-for="opt in searchOptions"
            :key="opt.label"
            :label="opt.label"
            :value="opt.value"
          ></vh-option>
        </vh-select>
      </vh-input>
      <vh-button class="mb15" plain :disabled="!total" round size="medium" @click="exportData">
        导出订单
      </vh-button>
    </div>
    <div v-if="!total && !isSearch" class="oh">
      <null-page :nullType="'nullData'" :text="'暂无订单'"></null-page>
    </div>
    <vh-table
      v-if="total || isSearch"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      ref="multipleTable"
      :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
    >
      <span slot="empty"></span>
      <vh-table-column
        label="订单编号"
        prop="order_no"
        width="230"
        show-overflow-tooltip
      ></vh-table-column>
      <vh-table-column label="图片">
        <template slot-scope="scope">
          <div class="product-cover">
            <img
              :src="scope.row.order_items[0].cover_img"
              alt=""
              v-parseImgOss="{ url: scope.row.order_items[0].cover_img, default: 1 }"
            />
          </div>
        </template>
      </vh-table-column>
      <vh-table-column label="名称" width="156" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>
            {{ scope.row.order_items[0].name }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="支付金额" width="110">
        <template slot-scope="scope">
          <span>
            {{ scope.row.pay_amount ? `￥${scope.row.pay_amount}` : '--' }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="数量">
        <template slot-scope="scope">
          <span>
            {{ `${scope.row.order_items[0].quantity}` }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="优惠券扣减" width="110">
        <template slot-scope="scope">
          <span>
            {{ scope.row.coupon_amount ? `￥${scope.row.coupon_amount}` : '-' }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="下单时间" width="160" prop="created_at"></vh-table-column>
      <vh-table-column label="用户信息" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          <p>用户姓名：{{ `${scope.row.username}` }}</p>
          <p>手机号：{{ `${scope.row.phone}` }}</p>
          <p>参会ID： {{ `${scope.row.join_id}` }}</p>
          <p>参会昵称：{{ `${scope.row.nickname}` }}</p>
        </template>
      </vh-table-column>
      <vh-table-column label="购买方式" width="120" prop="buy_type">
        <template slot-scope="scope">
          <span>
            {{ scope.row.buy_type | buyTypeFilter }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="支付方式" prop="pay_channel">
        <template slot-scope="scope">
          <span>
            {{ scope.row.pay_channel | payChannelFilter }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="订单状态" width="120">
        <template slot-scope="scope">
          <span>
            {{ scope.row.order_status | orderStatusFilter }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column label="所属活动" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>
            {{ `${scope.row.webinar_subject}/${scope.row.webinar_id}` }}
          </span>
        </template>
      </vh-table-column>
      <vh-table-column
        label="渠道来源"
        width="150"
        prop="channel_source_name"
        show-overflow-tooltip
      ></vh-table-column>
      <vh-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <vh-button v-preventReClick class="btns" type="text" @click="toDetail(scope.row)">
            查看
          </vh-button>
        </template>
      </vh-table-column>
    </vh-table>
    <SPagination
      :total="total"
      :page-size="searchParams.page_size"
      :currentPage="searchParams.page"
      @current-change="currentChange"
      @size-change="sizeChange"
    ></SPagination>
    <null-page
      text="暂未搜索到您想要的内容"
      nullType="search"
      v-if="total === 0 && isSearch"
    ></null-page>
    <order-detail ref="orderDetailRef"></order-detail>
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import { OrderDetail } from '@vhcl/product';
  import { getOrderList, getChannelList, exportOrderList } from '@vhcl/product';
  import { orderStatus, searchOptions, buyTypeList } from './orderSearchCondition';
  import { buyTypeMap } from './list.vue';

  export default {
    name: 'ProductOrderList',
    props: {
      isInWebinar: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        isSearch: false,
        customPlaceholder: '请输入订单编号',
        searchParams: {
          page_size: 10,
          page: 1,
          webinar_id: this.$route.params.str || '',

          start_date: '',
          end_date: '',

          channel_source_name: '',
          order_status: '',
          buy_type: '',

          order_no: '',
          goods_name: '',
          webinar_info: '',
          address_info: ''
        },
        pickDate: '',
        customSearchOpt: 'order_no',
        customSearchOptVal: '',
        orderStatus,
        buyTypeList,
        channelList: [],
        searchOptions,
        tableData: [], // 列表数据
        total: 0 // 列表总数
      };
    },
    components: {
      NullPage,
      OrderDetail
    },
    created() {
      this.getList('clearPage');
      this.getChannelList();
    },
    filters: {
      buyTypeFilter(val) {
        return buyTypeMap[val];
      },
      payChannelFilter(val) {
        const map = {
          WEIXIN: '微信',
          ALIPAY: '支付宝'
        };
        return val ? map[val] : '--';
      },
      orderStatusFilter(val) {
        const map = {
          SUCCESS: '支付成功',
          FAILED: '支付失败',
          WAITING: '待支付',
          CREATED: '订单已创建'
        };
        return val ? map[val] : '--';
      }
    },
    methods: {
      changeCustomPlaceholder(val) {
        this.customPlaceholder = this.searchOptions.find(item => item.value === val).placeholder;
      },
      setIsSearch() {
        for (let key in this.searchParams) {
          if (
            Object.prototype.hasOwnProperty.call(this.searchParams, key) &&
            !['page_size', 'page', 'webinar_id'].includes(key)
          ) {
            if (this.searchParams[key]) {
              this.isSearch = true;
              return;
            }
          }
          this.isSearch = false;
        }
      },
      changeDate(val) {
        console.log(val);
        if (val) {
          this.searchParams.start_date = val[0];
          this.searchParams.end_date = val[1];
        } else {
          this.searchParams.start_date = '';
          this.searchParams.end_date = '';
        }
        this.getList('clearPage');
      },
      exportData() {
        const params = this.getRequestParams();
        exportOrderList(params).then(res => {
          if (res.code == 200) {
            this.$vhMessage.success(
              `导出成功， ${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`
            );
          } else {
            this.$vhMessage.error('导出失败');
          }
        });
      },
      getChannelList() {
        const params = {};
        if (this.isInWebinar) params.webinar_id = this.searchParams.webinar_id;
        getChannelList(params).then(res => {
          if (res.code == 200) {
            this.channelList = res.data.map(item => ({
              label: item.channel_name,
              value: item.channel_name
            }));
            this.channelList.unshift({
              label: '全部渠道',
              value: false
            });
          }
        });
      },
      getRequestParams() {
        this.searchParams.order_no = '';
        this.searchParams.goods_name = '';
        this.searchParams.webinar_info = '';
        this.searchParams.address_info = '';
        this.searchParams[this.customSearchOpt] = this.customSearchOptVal;

        return {
          ...this.searchParams,
          channel_source_name: this.searchParams.channel_source_name || '',
          order_status: this.searchParams.order_status || '',
          buy_type: this.searchParams.buy_type || '',
          pos: (this.searchParams.page - 1) * this.searchParams.page_size,
          limit: this.searchParams.page_size
        };
      },
      getList(type) {
        if (type == 'clearPage') {
          this.searchParams.page = 1;
        }
        const params = this.getRequestParams();
        this.setIsSearch();
        getOrderList(params).then(res => {
          if (res.code == 200) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          }
        });
      },
      toDetail(row) {
        this.$refs.orderDetailRef.open(row.order_no);
      },
      currentChange(val) {
        this.searchParams.page = val;
        this.getList();
      },
      sizeChange(val) {
        this.searchParams.page = 1;
        this.searchParams.page_size = val;
        this.getList();
      }
    }
  };
</script>

<style lang="less" scoped>
  .product-order-wrap {
    min-height: 572px;
    .mr10 {
      margin-right: 10px;
    }
    .mb15 {
      margin-bottom: 15px;
    }
    .oh {
      overflow: hidden;
    }
    .head-operate {
      margin-bottom: 8px;
    }
    .product-cover {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 4px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    ::v-deep .input-with-select {
      .vh-input-group__prepend {
        border-radius: 100px 0 0 100px;
      }
      .vh-input__inner {
        border-radius: 0 100px 100px 0;
      }
      .vh-input--suffix {
        .vh-input__inner {
          padding-left: 11px;
        }
      }
    }
  }
</style>
