<template>
  <vh-col :span="colSpan" class="stats-cell">
    <!-- 头部 -->
    <div class="stats-cell__hd">
      <span class="stats-cell__title">{{ title }}</span>
      <vh-tooltip
        v-if="!!$props.tipContent || !!$slots.tipContent"
        v-bind="$attrs"
        :effect="effect"
        :placement="placement"
        :content="tipContent"
      >
        <div slot="content" v-if="$slots.tipContent">
          <slot name="tipContent"></slot>
        </div>
        <i class="iconfont-v3 saasicon_help_m"></i>
      </vh-tooltip>
    </div>
    <!-- 内容区 -->
    <div class="stats-cell__bd">
      <slot></slot>
    </div>
  </vh-col>
</template>
<script>
  import { Tooltip } from 'vhall-ui';
  export default {
    name: 'StatsCell',
    props: {
      title: String,
      colSpan: {
        type: Number,
        default: 4
      },
      tipContent: {
        String
      },
      placement: {
        type: String,
        default: 'right'
      },
      effect: {
        type: String,
        default: 'dark'
      }
    }
  };
</script>
<style lang="less" scoped>
  .stats-cell {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    &__hd {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #999;
      .saasicon_help_m {
        margin-left: 2px;
      }
    }

    &__bd {
      padding-top: 6px;
      font-size: 28px;
      font-weight: 700;
      color: #1a1a1a;
    }
  }
</style>
